import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Mousewheel } from "swiper"
import { GatsbyImage } from "gatsby-plugin-image"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import "swiper/css"
import "swiper/css/grid"

const BlogPostMainSlider = ({ postData }) => {
  const [showSlider, setShowSlider] = useState(true)
  useEffect(() => {
    setShowSlider(window.matchMedia(`(min-width: 900px)`).matches)
    const debounce = (func, delay) => {
      return function () {
        setTimeout(() => {
          func()
        }, delay)
      }
    }
    window.addEventListener(
      "resize",
      debounce(function () {
        setShowSlider(window.matchMedia(`(min-width: 900px)`).matches)
      }, 200)
    )
  }, [])

  const categoryTitle = postData.categories.nodes[0].name
  const inspirationStyling =
    categoryTitle === "Inspiration" ? "inspiration_hidden" : ""
  SwiperCore.use([Mousewheel])

  const imagesList = Object.values(postData.custom_field_feature_images).filter(
    el => el !== null
  )
  const featureImagesSwiper = imagesList.map(featureImagesSlider => {
    return (
      <SwiperSlide key={featureImagesSlider.localFile.childImageSharp.id}>
        {/* <Zoom transitionDuration={700} zoomMargin={100}> */}
          {/* <img src={featureImagesSlider.mediaItemUrl}/> */}
          <GatsbyImage
            image={
              featureImagesSlider.localFile.childImageSharp.gatsbyImageData
            }
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "cover", width: "auto" }}
            alt={postData.title}
          />
        {/* </Zoom> */}
        {/* {featureImagesSlider.description ? (
          <div className="blog-post-description">
            {parse(featureImagesSlider.description)}
          </div>
        ) : null} */}
      </SwiperSlide>
    )
  })
  const featureBlockImages = imagesList.map(featureImagesSlider => {
    return (
      <div
        className="feature_block_mobile"
        key={featureImagesSlider.localFile.childImageSharp.id}
      >
        <Zoom transitionDuration={1000}zoomMargin={100}>
          <GatsbyImage
            image={
              featureImagesSlider.localFile.childImageSharp.gatsbyImageData
            }
            style={{ width: "100%" }}
            imgStyle={{ objectFit: "cover", width: "auto" }}
            alt={postData.title}
          />
        </Zoom>
      </div>
    )
  })
  return showSlider ? (
    <Swiper
      mousewheel={true}
      grabCursor={true}
      breakpoints={{
        900: {
          updateOnWindowResize: true,
          slidesPerView: "auto",
          direction: "horizontal",
          spaceBetween: 45,
          speed: 1000,
        },
      }}
      className="mySwiper blogPostSwiper"
    >
      {featureImagesSwiper}
      <div className={`post_title ${inspirationStyling}`}>{postData.title}</div>
    </Swiper>
  ) : (
    featureBlockImages
  )
}

export default BlogPostMainSlider

import React, {useState} from "react"
import { Link, graphql } from "gatsby"
import BlogPostMainSlider from "../components/Sliders/BlogPostMainSlider"
import Modal from "../components/Elements/Modal"
import parse from "html-react-parser"

import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const [showModal, setShowModal] = useState(false)
  
  const openModal = () =>{
    setShowModal(prev => !prev)
  };

  const categoryTitle = post.categories.nodes[0].name;
  const inspirationClass = categoryTitle === 'Press'? 'inspiration_template' : '';
  const inspirationStyle = categoryTitle === 'Press'? 'inspiration_styling' : '';
  const inspirationProject = categoryTitle === 'Press'? 'inspiration_about_project' : '';
  const inspirationNav = categoryTitle === 'Press'? 'inspiration_nav' : '';
  return (
    <Layout isBlogPost>
       <Seo title={post.title} description={post.excerpt} />
      <div className={`overflow-blogposts ${inspirationClass}`}>
      <h4 className="desktop-hidden">{parse(post.title)}</h4>
        {post.content ? (
          <div className="post-content-wrapper-mobile desktop-hidden"> 
            {parse(post.content)}
          </div>
        ) : null}
        <div className="blogpost_container">
        <Modal modalData={post} showModal={showModal} setShowModal={setShowModal}/>
          <BlogPostMainSlider postData={post} />
          <h4 className={`about_the_project mobile-hidden ${inspirationProject}`}>About the Project</h4>
          {post.content ? (
            <div className={`post-content-wrapper mobile-hidden ${inspirationStyle}`}>
              <button onClick={openModal} className="blog_readmore"><span className="blog_btn_span">READ MORE</span></button>
              {(categoryTitle === 'Press')?<h4 class="inspiration_title">{parse(post.title)}</h4>:null}
              {parse(post.content)}
            </div>
          ) : null}
        <div className="nav-container">
          <nav className={`next-previous_blogs pagination_container mobile-hidden ${inspirationNav}`}>
            <ul
              className={categoryTitle === 'Press' ? 'inspiration_ul' : ''}
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li className={categoryTitle !== 'Press'? 'li_absolute' : ''}>
                {previous && (
                  <Link to={previous.uri} rel="prev">
                    {categoryTitle === 'Press'? 'Previous Article' : 'Previous'}
                  </Link>
                )}
              </li>
              {(categoryTitle === 'Press')?<Link target="_blank" to={post.sourcelink.sourceLink.url}><li className="inspiration_source">Source</li></Link>:null}      
              <li>
                {next && (
                  <Link to={next.uri} rel="next">
                    {categoryTitle === 'Press' ? 'Next Article' : 'Next'}
                  </Link>
                )}
              </li>
            </ul>
          </nav>
          </div>
        </div>
        <nav className="next-previous_blogs pagination_container desktop-hidden">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.uri} rel="prev">
                    <span className="arrows_pagination previous_arrow"></span>
                  </Link>
                )}
              </li>
              {(categoryTitle === 'Press')?<Link target="_blank" to={post.sourcelink.sourceLink.url}><li className="inspiration_source">Source</li></Link>:null}      
              <li>
                {next && (
                  <Link to={next.uri} rel="next">
                   <span className="arrows_pagination next_arrow"></span>
                  </Link>
                )}
              </li>
            </ul>
          </nav>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      content
      title
      excerpt
      custom_field_feature_images {
        firstFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
              id
            }
          }
        }
        secondFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
              id
            }
          }
        }
        thirdFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
              id
            }
          }
        }
        fourthFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        fifthFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        sixthFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        seventhFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        eighthFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        ninethFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        tenthFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        elevenFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twelveFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        thirteenFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        fourteenFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        fifteenFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        sixteenFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        seventeenFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        eighteenFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        nineteenFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentyFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentyoneFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentytwoFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentythreeFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentyfourFeatureImage {
          mediaItemUrl
          altText
          description
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentyfiveFeatureImage {
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentysixFeatureImage {
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentysevenFeatureImage {
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentyeightFeatureImage {
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        twentynineFeatureImage {
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        thirtyFeatureImage {
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
      }
      sourcelink {
        sourceLink {
          url
        }
      }
      categories {
        nodes {
          name
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`

import React, {useRef} from "react"
import parse from "html-react-parser"
import CloseX from "../../Images/Close_menu.svg"


const Modal = ({showModal, setShowModal, modalData}) => {
    const modalRef = useRef();
    const closeModal = e => {
        if (modalRef.current === e.target){
            setShowModal(false)
        }
    };
    return(
        <>
        {showModal ? <div className="backdrop" ref={modalRef} onClick={closeModal}>
            <div className="modal_container">
                <img className="close_btn" onClick={() => setShowModal(prev => !prev)} src={CloseX}/>
                <div className="modal_title">
                {parse(modalData.title)}
                </div>
                <div className="modal_content">
                {parse(modalData.content)}
                </div>
            </div>
        </div> : null}
        </>
    )
}

export default Modal;
